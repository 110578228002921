import React from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
//import Hero from '../components/Hero/Hero'


const Posts = ({ data: { posts = {},  recentPosts = {},featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
        {/*<Stack>*/}
        {/*    /!*<Main>*!/*/}
        {/*    /!*    <Hero />*!/*/}
        {/*    /!*</Main>*!/*/}
        {/*/!*</Stack>*!/*/}
        {/*<Divider />*/}
      <Stack>
        <Main>
            <CardList
                nodes={recentPosts.nodes}
                variant={['horizontal-md', 'vertical']}
                columns={[1, 2, 3, 3]}
                omitCategory={
                    props.pageContext &&
                    props.pageContext.collectionType === 'category'
                }
                loading='lazy'
            />
        </Main>
      </Stack>
      <Stack effectProps={{ loading: 'lazy' }}>
        <Main>
                {services.mailchimp && (
                    <>
                        <Divider />
                        <NewsletterExpanded simple />
                    </>
                )}
            </Main>
        </Stack>
    </Layout>
  )
}

export default Posts
